<template>
  <!-- 农村金融 -->
  <div class="Loans">
    <div class="finance-management">
      <!-- 展示图片 -->
      <div class="logo"></div>

      <!-- 搜索e快贷 -->
      <div class="SearchLoan">
        <div class="SearchLoanAll">
          <div class="SearchItem">
            <span>产品名称：</span>
            <el-input v-model="productName" placeholder="e快贷"></el-input>
          </div>
          <div class="SearchItem">
            <span>机构名称：</span>
            <el-input
              v-model="OrganizationName"
              placeholder="请输入机构名称"
            ></el-input>
          </div>
          <div class="SearchItem">
            <el-button size="mini" type="primary" @click="SearchItem()"
              >搜索</el-button
            >
          </div>
        </div>
      </div>

      <!-- 产品筛选 -->
      <div class="ProductScreening">
        <h2>产品筛选</h2>
        <div class="ScreeningCondition">
          <div class="LoanTerm">
            <div class="title">
              <span>贷款期限:</span>
            </div>
            <div class="content">
              <div @click="Clear1()" :class="flag == true ? 'active' : ''">
                全部
              </div>
              <div
                v-for="(item, index) in LoanTerm"
                :key="index"
                @click="LoanTerms(item.value, index)"
                :class="i == item.value ? 'active' : ''"
              >
                {{ item.description }}
              </div>
            </div>
          </div>
          <div class="LoanAmount">
            <div class="title">
              <span>贷款额度:</span>
            </div>
            <div class="content">
              <div @click="Clear2()" :class="flag2 == true ? 'active' : ''">
                全部
              </div>
              <div
                v-for="(item, index) in LoanAmount"
                :key="index"
                @click="LoanAmounts(item.value, index)"
                :class="i2 == item.value ? 'active' : ''"
              >
                {{ item.description }}
              </div>
            </div>
          </div>
          <div class="GuaranteeMethods">
            <div class="title">
              <span>贷款方式:</span>
            </div>
            <div class="content">
              <div @click="Clear3()" :class="flag3 == true ? 'active' : ''">
                全部
              </div>
              <div
                v-for="(item, index) in GuaranteeMethods"
                :key="index"
                @click="GuaranteeMethod(item.value, index)"
                :class="i3 == item.value ? 'active' : ''"
              >
                <span> {{ item.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 贷款列表 -->
      <div class="LoanList" v-if="FinancialList.length">
        <div
          class="LoansItem"
          v-for="(item, index) in FinancialList"
          :key="index"
        >
          <div class="LeftBox">
            <img
              :src="'/api/admin/file/get?ossFilePath=' + item.logoUrl"
              alt=""
            />
          </div>
          <div class="RightBox">
            <div class="LoanTitle">{{ item.title }}</div>
            <div class="LoanLimit">
              <div class="LoanType">
                <span class="BigStyle">
                  {{
                    item.minMoney == item.maxMoney || item.minMoney == 0
                      ? item.ToMaxMoney
                      : item.ToMinMoney + "~" + item.ToMaxMoney
                  }}</span
                >
                <span>贷款额度</span>
              </div>
              <div class="LoanTerm">
                <span class="BigStyle">
                  {{
                    item.minTime == item.maxTime || item.minTime == 0
                      ? item.maxTime + "个月"
                      : item.minTime + "个月~" + item.maxTime + "个月"
                  }}
                </span>
                <span>贷款期限</span>
              </div>
              <!-- {{ item.creditType }} -->
              <div class="GuaranteeMode">
                <span class="BigStyle">
                  {{ item.creditTypeName }}
                </span>
                <span>担保方式</span>
              </div>
              <div class="LoanInterestRate">
                <span class="BigStyle">
                  {{
                    item.minRate == item.maxRate || item.minTime == 0
                      ? item.maxRate + "%"
                      : item.minRate + "%~" + item.maxRate + "%"
                  }}
                </span>
                <span>贷款利率</span>
              </div>
              <div class="LoanDetails">
                <el-button
                  type="primary"
                  @click="LoanDetails(item.financeProductId)"
                  >详情</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else  class="width:100%;">
        <div style=" text-align: center; padding-top:20px">
               暂无数据
        </div>
      </div>

      <!-- 分页 -->
      <div class="Pagination">
        <div class="PaginationRight">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pnum"
            :page-size="psize"
            :page-sizes="[5]"
            :total="total"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { recordInquiry, ScreeningCondition } from "@/api/RuralFinance";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      FinancialList: [], //金融管理列表
      pnum: 1, //页码
      psize: 5, //每页数量
      total: 0, //总数量
      productName: "", //产品名称
      OrganizationName: "", //机构名称
      LoanTerm: [], //贷款期限条件列表
      LoanAmount: [], //贷款额度条件列表
      GuaranteeMethods: [], //贷款方式条件列表,
      Conditions: {}, //存储搜索结果

      i: 0,
      i2: 0,
      i3: 0,
      flag: true,
      flag2: true,
      flag3: true,
    };
  },
  watch: {
    topSelectAreacode: function () {
      // if (
      //   this.topSelectAreacode.length > 4 &&
      //   this.topSelectAreacode !== undefined
      // ){
      //  this.AllRecordInquiry();
      // } else{
      //   this.$message.info("请选择所属区县，查看对应产品");
      // }
      if (
        (  typeof this.topSelectAreacode == "undefined" ||this.topSelectAreacode.length < 4)

      ) {
        this.$message.info("请选择所属市，查看对应产品");
        this.FinancialList=[]
      } else {
        this.AllRecordInquiry();
      }

    },
  },
  mounted() {
    this.ScreeningCondition1();
    this.ScreeningCondition2();
    this.ScreeningCondition3().then(this.AllRecordInquiry());
    //担保方式
    this.ScreeningCondition();
    //担保方式转成数字
    this.AllRecordInquiry;
  },
  computed: {
    ...mapGetters(["topSelectAreacode"]),
  },
  methods: {
    // 总的查询列表
    async ConditionsList(Conditions) {
      if(typeof this.topSelectAreacode == "undefined" ||this.topSelectAreacode.length < 4){
        this.FinancialList =[]
      }else{
           let areaCode = this.topSelectAreacode ? this.topSelectAreacode : "";
          let params = {
            pnum: this.pnum,
            psize: this.psize,
            areaCode: areaCode,
            ...Conditions,
          };
          let res = await recordInquiry(params);
          this.FinancialList = res.data;
          this.AllRecordInquiry();
      }

    },
    //每页数据
    handleSizeChange(psize) {
      this.pnum = 1;
      this.psize = psize;
      this.AllRecordInquiry();
    },
    // 当前页
    handleCurrentChange(pnum) {
      (this.psize = 5), (this.pnum = pnum);
      this.AllRecordInquiry();
    },
    //贷款期限条件
    async ScreeningCondition1() {
      let params = "loan_time";
      let res = await ScreeningCondition(params);
      this.LoanTerm = res.data;
    },
    //贷款额度条件
    async ScreeningCondition2() {
      let params = "loan_money";
      let res = await ScreeningCondition(params);
      this.LoanAmount = res.data;
    },
    //贷款方式条件
    async ScreeningCondition3() {
      let params = "credit_type";
      let res = await ScreeningCondition(params);
      this.GuaranteeMethods = res.data;
    },
    //担保方式
    async ScreeningCondition() {
      let params = "credit_type";
      let res = await ScreeningCondition(params);
      this.guarantee = res.data;
    },
    //清空当前搜索条件
    Clear1() {
      this.Conditions.maxTime = "";
      this.ConditionsList(this.Conditions);
      this.i = 0;
      this.flag = true;

    },
    Clear2() {
      this.Conditions.maxMoney = "";
      this.ConditionsList(this.Conditions);
      this.i2 = 0;
      this.flag2 = true;
    },
    Clear3() {
      this.Conditions.creditType = "";
      this.ConditionsList(this.Conditions);
      this.i3 = 0;
      this.flag3 = true;
    },
    // 筛选
    LoanTerms(value, index) {
      this.i = index + 1;
      this.flag = false;
      this.Conditions.maxTime = value;
      this.ConditionsList(this.Conditions);
    },
    LoanAmounts(value, index) {
      this.i2 = index + 1;
      this.flag2 = false;
      this.Conditions.maxMoney = value;
      this.ConditionsList(this.Conditions);
    },
    GuaranteeMethod(value, index) {
      this.i3 = index + 1;
      this.flag3 = false;
      this.Conditions.creditType = value;
      this.ConditionsList(this.Conditions);
    },
    // 输入框筛选
    async SearchItem() {
      this.Conditions.title = this.productName;
      this.Conditions.departName = this.OrganizationName;

          let areaCode = this.topSelectAreacode ? this.topSelectAreacode : "";
          let params = {
            pnum: this.pnum,
            psize: this.psize,
            areaCode: areaCode,
            title:this.productName,
            departName: this.OrganizationName
          };
          console.log('查看这里的params',params)
          let res = await recordInquiry(params);
          this.FinancialList = res.data;
          this.AllRecordInquiry();

      this.Conditions.title = "";
      this.Conditions.departName = "";
      this.productName = "",
      this.OrganizationName = "";
    },
    // 贷款方式条件
    async AllRecordInquiry() {
      if( typeof this.topSelectAreacode == "undefined" ||this.topSelectAreacode.length < 4){
        this.$message.info("请选择所属市，查看对应产品");
        this.FinancialList=[]
      }else{
       let areaCode = this.topSelectAreacode ? this.topSelectAreacode : "";
      let params = {
        pnum: this.pnum,
        psize: this.psize,
        status: 1,
        areaCode: areaCode,
        ...this.Conditions,
      };
      let res = await recordInquiry(params);
      this.FinancialList = res.data;
      this.total = res.dataNum;
      this.ToWay();
      }

    },
    ToWay() {
      let ToGuaranteeMethods = this.GuaranteeMethods;
      this.FinancialList.forEach((item, index) => {
        if (item.minMoney >= 10000) {
          const newMinMoney = item.minMoney / 10000 + "万元";
          item.ToMinMoney = newMinMoney;
        } else if (item.minMoney < 10000) {
          const newMinMoney = item.minMoney + "元";
          item.ToMinMoney = newMinMoney;
        }

        if (item.maxMoney >= 10000) {
          const newMaxMoney = item.maxMoney / 10000 + "万元";
          item.ToMaxMoney = newMaxMoney;
        } else {
          const newMaxMoney = item.maxMoney + "元";
          item.ToMaxMoney = newMaxMoney;
        }
        let credit_type_name = "";
        let arr = Array.from(item.creditType.split(","));
        for (let i = 0; i < ToGuaranteeMethods.length; i++) {
          if (arr.indexOf(ToGuaranteeMethods[i].value) > -1) {
            credit_type_name += ToGuaranteeMethods[i].description + " ";
          }
        }
        item.creditTypeName = credit_type_name;
      });
    },
    //贷款详情
    LoanDetails(id) {
      this.$router.push({
        path: "/home/LoanDetails",
        query: {
          // url的参数, 类似get请求的传参
          id,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.finance-management {
  padding-top: 40px;
  width: 1472px;
  margin: 0 auto;
  padding-bottom: 54px;
  text-align: left;

  .logo {
    width: 100%;
    height: 468px;
    background-color: #ffbcbc;
    background-image: url("../../assets/imgs/finance-logo.png");
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
  }

  .SearchLoan {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .SearchLoanAll {
      width: 60%;
      display: flex;
      justify-content: space-around;
      .SearchItem {
        display: flex;
        align-items: center;
        span {
          width: 150px;
          text-align: center;
        }
        /deep/.el-input__inner {
          border-radius: 20px;
          height: 35px;
          border-right: none;
        }
      }
    }
  }

  .ProductScreening {
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    background: #eaf4d9;
    h2 {
      margin-left: 30px;
    }
    .ScreeningCondition {
      width: 85%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .LoanTerm {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-around;
        .title {
          width: 30%;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .content {
          display: flex;
          width: 70%;
          div {
            width: 100px;
            display: flex;
            margin: 10px;
            justify-content: space-around;
            align-items: center;
            background: white;
            border-radius: 20px;
          }
          .active {
            background: #6dc1ff;
          }
        }
      }
      .LoanAmount {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-around;
        .title {
          width: 30%;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .content {
          display: flex;
          width: 70%;
          div {
            width: 100px;
            display: flex;
            margin: 10px;
            justify-content: space-around;
            align-items: center;
            background: white;
            border-radius: 20px;
          }
          .active {
            background: #6dc1ff;
          }
        }
      }
      .GuaranteeMethods {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-around;
        .title {
          width: 30%;
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
        .content {
          display: flex;
          width: 70%;
          div {
            width: 100px;
            display: flex;
            margin: 10px;
            justify-content: center;
            align-items: center;
            background: white;
            border-radius: 20px;
          }
          .active {
            background: #6dc1ff;
          }
        }
      }
    }
  }

  .LoanList {
    width: 100%;
    display: flex;
    flex-direction: column;
    .LoansItem {
      width: 100%;
      height: 100px;
      display: flex;
      border-bottom: solid 2px #ccc;
      margin-top: 10px;
      justify-content: space-between;
      .LeftBox {
        width: 20%;
        display: flex;
        justify-content: space-around;
        line-height: 50px;
        img {
          width: 100%;
          height: 95%;
        }
      }
      .RightBox {
        width: 75%;
        display: flex;
        flex-direction: column;
        span {
          text-align: center;
        }
        .LoanTitle {
          width: 20%;
          height: 40%;
          font-size: 19px;
          text-align: center;
        }
        .LoanLimit {
          display: flex;
          height: 60%;
          justify-content: space-between;
          div {
            flex: 1;
            display: flex;
            flex-direction: column;
            .BigStyle {
              font-size: 19px;
            }
          }
          .LoanDetails {
            text-align: right;
            .el-button {
              width: 50%;
            }
          }
        }
      }
    }
  }
  .Pagination {
    width: 100%;
    height: 100px;
    display: flex;
    margin-top: 20px;
    justify-content: flex-end;

    .PaginationRight {
      width: 50%;
      height: 100%;
    }
  }
}
</style>

<style lang="less" scoped>
.finance-announce {
  .el-form-item {
    margin-bottom: 0;
    margin-top: 16px;
  }
  .el-input__inner {
    height: 35px;
  }
}
</style>
